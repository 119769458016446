/*
 * Main Script File
 * Author: Mario Jahn
 */

import Splide from "@splidejs/splide";
import MasonryLayout from "@appnest/masonry-layout";
import ScrollPadlock from "scroll-padlock";
const scrollPadlock = new ScrollPadlock();

document.addEventListener("DOMContentLoaded", function () {
	/*####### SCROLL PADLOCK TOGGLE #######*/
	function addClassToBody() {
		const checkbox = document.getElementById("main-menu__state");
		const body = document.body;

		checkbox.addEventListener("change", function () {
			if (checkbox.checked) {
				body.classList.add("scroll-padlock-locked");
			} else {
				body.classList.remove("scroll-padlock-locked");
			}
		});
	}
	addClassToBody();

	/*####### SHRINK HEADER #######*/
	const sentinel = document.getElementById("header-listener");
	const header = document.getElementById("header");

	const options = {
		root: document,
		rootMargin: "0px",
		threshold: 0,
	};

	const callback = (entries, observer) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				header.classList.remove("header--small");
			} else {
				header.classList.add("header--small");
			}
		});
	};
	const observer = new IntersectionObserver(callback, options);
	observer.observe(sentinel);

	/*####### REMOVE SMALL LOGO HOME AFTER HITTING 2ND MENU #######*/
	if (document.getElementById("home__header")) {
		const sentinel_02 = document.getElementById("home__header");
		const header_02 = document.getElementById("header__bar");

		const callback02 = (entries02, observer) => {
			entries02.forEach((entry) => {
				if (entry.isIntersecting) {
					header_02.classList.remove("header__bar--wo_logo");
				} else {
					header_02.classList.add("header__bar--wo_logo");
				}
			});
		};
		const observer02 = new IntersectionObserver(callback02, options);
		observer02.observe(sentinel_02);
	}

	/*####### SPLIDE SLIDER #######*/
	if (document.querySelectorAll(".splide--home").length) {
		new Splide(".splide--home", {
			type: "loop",
			arrows: false,
			pagination: true,
			paginationKeyboard: true,
			perPage: 1,
			autoplay: true,
		}).mount();
	} else if (document.querySelectorAll(".splide").length) {
		var elms = document.getElementsByClassName("splide");

		for (var i = 0; i < elms.length; i++) {
			var splide = new Splide(elms[i], {
				type: "loop",
				arrows: false,
				pagination: true,
				paginationKeyboard: true,
				perPage: 1,
			});

			splide.on("mounted", function () {
				// if fewer slides than provided in options, set option to the number of slides
				if (splide.length <= splide.options.perPage) {
					splide.options.type = "fade";
					splide.options.pagination = false;
				}
			});

			splide.mount();
		}
	}

	/*####### GLOSSARY LINKS #######*/
	/**
	 * Function to handle the click event on glossary links and save data-attribute value in local storage.
	 */
	function handleGlossaryLinkClick() {
		const glossaryLinks = document.querySelectorAll(".glossary-link");

		glossaryLinks.forEach((link) => {
			link.addEventListener("click", function () {
				const dataValue = this.getAttribute("data-value");
				localStorage.setItem("glossaryData", dataValue);
			});
		});
	}
	if (document.querySelectorAll(".glossary-link").length) {
		handleGlossaryLinkClick();
	}

	/*####### FACETWP CUSTOMISATIONS #######*/
	if (document.querySelectorAll(".facetwp-template").length) {
		const masonryContainer = document.getElementById("journal__articles");
		document.addEventListener("facetwp-refresh", function () {
			if (FWP.loaded) {
				fUtil(".facetwp-template").addClass("is-loading");
			}

			masonryContainer.scheduleLayout();
		});
		FWP.hooks.addAction("facetwp/loaded", function () {
			fUtil(".facetwp-template").removeClass("is-loading");
			masonryContainer.scheduleLayout();
		});
	}

	/*####### JOURNAL SINGLE INDEX #######*/
	/**
	 * Function to create an index with every h3 occurring on the page as a chapter
	 * and place the index in the element with the id "journal_single__index".
	 */
	if (document.querySelectorAll("#journal_single__index").length) {
		function createIndex() {
			// Get all the h3 elements on the page
			const h3Elements = document.querySelectorAll("h3.kapitel");

			// Create an index container element
			const indexContainer = document.createElement("ul");

			// Iterate over each h3 element to create index chapters
			h3Elements.forEach((h3Element, index) => {
				const chapter = document.createElement("li");
				const chapterLink = document.createElement("a");

				// Set the chapter title and link it to the corresponding h3 element
				chapterLink.textContent = h3Element.textContent;
				chapterLink.href = `#kapitel-${index + 1}`;

				// Append the chapter link to the chapter
				chapter.appendChild(chapterLink);

				// Append the chapter to the index container
				indexContainer.appendChild(chapter);

				// Assign an id to the h3 element for linking
				h3Element.id = `kapitel-${index + 1}`;
			});

			// Get the element with id "journal_single__index" to place the index
			const indexElement = document.getElementById("journal_single__index");

			// Append the index container to the element with id "journal_single__index"
			indexElement.appendChild(indexContainer);
		}

		// Call the createIndex function when the page loads
		createIndex();
	}
});
